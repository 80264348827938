.parallax {
    perspective: 1px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .parallax__layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .parallax__layer--base {
    transform: translateZ(0) translateY(100vh);
    z-index: 0;
  }
  .parallax__layer--back {
    z-index:-1;
    /* transform: translateZ(-1px); */
    transform: translateZ(-1px) scale(2);
  }
